<template>
  <div class="section pay-options" v-if="firstPayOptionsLoaded && tabsVisible">
    <h2>{{ translations("HowToPay") }}</h2>
    <div class="tabs-area">
      <tabs :options="{ useUrlFragment: false }">
        <tab v-for="tab in tabs" :key="tab.id" :name="tab.name">
          <h3>{{ tab.title }}</h3>
          <div
            class="payment-options"
            :class="[
              { 'two-options': tab.id === 0 },
              { 'tree-options': tab.id === 1 },
            ]"
          >
            <div
              class="payment-option"
              v-for="item in listFirstPayOptions[tab.id]"
              :key="item.id"
            >
              <div class="image">
                <img :src="'//' + serverLink + item.image" :alt="item.name" />
              </div>
              <div class="title">
                <span class="title-text">{{ item.name }}</span>
                <v-popover class="inline">
                  <button class="popup" v-if="item.popup">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4ZM7.2 4H8.8V5.6H7.2V4ZM7.2 7.2H8.8V12H7.2V7.2Z"
                        fill="#7D7E8B"
                      />
                    </svg>
                  </button>
                  <span
                    class="popup-hidden"
                    v-html="item.popup"
                    slot="popover"
                    >{{ item.popup }}</span
                  >
                </v-popover>
              </div>
            </div>
          </div>
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters, mapActions } from "vuex";
import Tab from "@/components/app/tabs/Tab";
import Tabs from "@/components/app/tabs/Tabs";

export default {
  name: "PayOptions",
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      activeTab: 1, // yur litsa
      tabs: [],
      popups: [],
      tabsVisible: false,
    };
  },
  components: {
    Tab,
    Tabs,
  },
  computed: {
    ...mapGetters([
      "translations",
      "firstPayOptionsLoaded",
      "listFirstPayOptions",
    ]),
  },
  methods: {
    ...mapActions(["fetchFirstPayOptions"]),
  },
  async mounted() {
    this.tabs = [
      {
        id: 1,
        name: this.translations("ForYurLitsa"),
        title: this.translations("BeznalTitle"),
      },
      {
        id: 0,
        name: this.translations("ForFizLitsa"),
        title: this.translations("PaymentOptions11Title"),
      },
    ];

    await this.fetchFirstPayOptions(this.$cookies.get("user_language"));
    this.tabsVisible = true;
  },
};
</script>