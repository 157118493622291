<template>
  <div class="contents-area foreign-registers-contents">
    <div class="full-content">
      <div class="tariff-countries">
        <div class="container">
          <div class="h1-breadcrumbs">
            <h1>{{ translations("SelectCountryForDocsPrices") }}</h1>
            <Breadcrumbs />
          </div>
          <span @click="getPriceList()" class="link price-list-link">{{
            translations("DownloadFullPriceList")
          }}</span>
          <CountryPanels :itemType="'tariffs'" />
        </div>
      </div>
      <div class="dimmed">
        <div class="container">
          <PayOptions />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters, mapActions } from "vuex";
import CountryPanels from "@/components/egrul/CountryPanels";
import PayOptions from "@/components/egrul/PayOptions";

export default {
  name: "ForeignRegisters",
  components: { CountryPanels, PayOptions },
  metaInfo() {
    return {
      title: this.translations("Tariffs") + " | " + this.listLang.site_name,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.listSettings.foreignregSettings.meta_desc,
        },
        {
          name: "keywords",
          content: this.listSettings.foreignregSettings.meta_keywords,
        },
      ],
    };
  },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
    };
  },
  computed: {
    ...mapGetters([
      "isCountriesLoaded",
      "isCountriesLoading",
      "listLang",
      "listSettings",
      "curCountryId",
      "translations",
    ]),
  },
  methods: {
    ...mapActions(["fetchCountries", "fetchPriceList"]),
    async getPriceList() {
      console.log("price all");
      await this.fetchPriceList({
        lang: this.$cookies.get("user_language"),
        countryId: 0,
      });
    },
  },
  async mounted() {
    if (!this.isCountriesLoaded && !this.isCountriesLoading) {
      await this.fetchCountries(this.$cookies.get("user_language"));
    }
  },
};
</script>
